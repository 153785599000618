<template>
    <div class="px-page mt-5">
        <h2>Ticket #{{ ticket.id }} - {{ ticket.subject }}</h2>
        <TicketState :state="ticket.state" />
        <div class="messages mt-5" ref="message">
            <p :class="message.is_support ? 'from-them' : 'from-me'" v-for="message in messages" :key="message.id">
                <small style="line-height: 2em;"><strong>User {{ ticket.user_id }}</strong> <cite>am {{ message.created_at }}</cite></small><br>
                <pre>{{ message.message }}</pre>
            </p>
        </div>
        <v-textarea solo label="Nachricht" v-model="message" />
        <div class="text-right">
            <v-btn rounded color="primary" large style="transform: translateY(-15px)" @click="sendMessage()">Absenden</v-btn>
        </div>
    </div>
</template>

<script>
import TicketState from '../../components/support/TicketState'
import xeonserv from '../../apis/xeonserv'

export default {
    components: { TicketState },
    data() {
        return {
            ticket: {
                id: this.$route.params.id,
                subject: ''
            },
            messages: [],
            message: '',
            messageTimer: undefined
        }
    },
    created() {
        this.loadTicket()
        this.loadMessages()
        this.messageTimer = setInterval(this.loadMessages, 1000)
    },
    methods: {
        loadTicket() {
            xeonserv().get('/support/tickets/' + this.ticket.id).then(res => {
                if(res.status == 404){
                    this.$router.push({ name: 'dashboard-support' })
                    return
                }
                this.ticket = res.data.data
            }, () => {
                this.$router.push({ name: 'dashboard-support' })
            })
        },
        loadMessages() {
            xeonserv().get('/support/tickets/' + this.ticket.id + '/messages').then(res => {
                const oldLength = this.messages.length
                this.messages = res.data.data
                if(oldLength != this.messages.length)
                    setTimeout(this.scrollDown, 100)
            })
        },
        sendMessage() {
            xeonserv().post('/support/tickets/' + this.ticket.id + '/messages', {
                message: this.message
            }).then(res => {
                this.messages.push(res.data.data)
                setTimeout(this.scrollDown, 100)
            })
            this.message = ''
        },
        scrollDown() {
            const el = this.$refs.message
            if(el !== undefined)
                el.scrollTop = 9999999
        }
    }
}
</script>

<style scoped>
.messages {
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
  padding: 0.5rem 1.5rem;
  max-height: 50vh;
  overflow-y: auto;
}

.messages::-webkit-scrollbar {
  width: 7px;
}

.messages::-webkit-scrollbar-track {
  background: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 20px;
}

.messages p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
  min-width: 30%;
}

.messages p pre {
  font-family: sans-serif;
  font-size: 18px;
}

.messages p::before,
.messages p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-them {
  align-self: flex-end;
  background-color: #e5e5ea;
  color: #000; 
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-them ~ p.from-them {
  margin: 0.25rem 0 0;
}

p.from-them ~ p.from-them:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-them ~ p.from-them:last-child {
  margin-bottom: 0.5rem;
}

p.from-me {
  align-items: flex-start;
  background-color: var(--primary);
  color: #fff;
}
</style>