<template>
    <v-chip small v-if="state == 'PENDING'" color="green" class="white--text">Warte auf Antwort</v-chip>
    <v-chip small v-else-if="state == 'OPEN'" color="green" class="white--text">Neue Antwort</v-chip>
    <v-chip small v-else-if="state == 'IN_PROGRESS'" color="orange" class="white--text">In Bearbeitung</v-chip>
    <v-chip small v-else-if="state == 'CLOSED'" color="red" class="white--text">Abgeschlossen</v-chip>
</template>

<script>
export default {
    props: {
        state: {
            type: String
        }
    }
}
</script>